<template>
  <div>
    <!-- showAdd: {{ showAdd }}<br />
    xx: {{ createDialog }}<br />
    showedit: {{ showEdit }}<br />
    offset: {{ offset }}<br /> -->
    <contentHeader title="ContentType" @add="createNew" />
    <!-- add dialog -->
    <q-dialog v-model="showEdit">
      <q-card style="width: 100%">
        <q-bar>
          <span class="form-title">{{ mode }} a contenttype</span>
          <q-space />
          <q-icon v-close-popup name="far fa-times" />
        </q-bar>

        <q-card-section>
          <q-form class="q-pa-md">
            <q-input
              v-model="current.name"
              filled
              label="Name"
              hint="Enter a unique and descriptive term"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Please type something']"
            />

            <div class="row">
              <div class="col-8">
                <q-input
                  v-model="current.icon"
                  filled
                  label="Icon"
                  hint="Enter name from Font Awesome 5 family"
                  lazy-rules
                  :rules="[(val) => (val && val.length > 0) || 'Please type something']"
                />
              </div>
              <div class="col-4">
                <q-icon
                  color="primary"
                  class="q-ml-xl primary"
                  size="xl"
                  :name="`fa fa-${current.icon}`"
                />
              </div>
            </div>
          </q-form>
        </q-card-section>

        <q-card-actions align="center">
          <q-btn label="Cancel" v-close-popup color="secondary" />
          <q-btn label="Save" @click="save" color="primary" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <section class="q-pa-md">
      <q-markup-table wrap-cells dense v-if="entries.length">
        <thead>
          <tr>
            <th>Name</th>
            <th>Icon name</th>
            <th>Icon view</th>
            <th class="actions">Actions</th>
          </tr>

          <tr :key="i" v-for="(e, i) in entries">
            <td>{{ e.name }}</td>
            <td>{{ e.icon }}</td>
            <td><q-icon :name="`fa fa-${e.icon}`" /></td>
            <td>
              <q-btn
                v-show="true"
                @click="cEdit(i)"
                color="primary"
                flat
                round
                dense
                icon="fad fa-pencil"
              />
              <q-btn
                @click="cDelete(i)"
                color="warning"
                flat
                round
                dense
                icon="fad fa-trash"
              />
            </td>
          </tr>
        </thead>
      </q-markup-table>
    </section>
  </div>
</template>
<script>
import { API } from "../tic";
import { mapGetters } from "vuex";
import contentHeader from "@/admin/components/contentHeader";
import tagOptions from "@/components/hiTags";

import tag from "@/models/tag";

export default {
  name: "admin-contenttype",
  props: ["showAdd", "title"],
  components: { contentHeader, tagOptions },
  mounted() {
    this.load();
  },

  data() {
    return {
      showEdit: false,
      createDialog: false,
      mode: "Add",
      tag: { ...tag.model },
      record: { icon: "", name: "" },
      current: {},
      icon: "",
      parent: "",
      entries: [],
      offset: 0,
    };
  },

  computed: {
    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),
  },

  methods: {
    cancel() {
      this.showEdit = false;
    },

    cEdit(offset) {
      this.mode = "Edit";
      console.log("cedit");
      this.current = { ...this.entries[offset] };
      this.showEdit = true;
    },

    createNew() {
      this.mode = "Add";
      this.current = { ...this.record };
      this.showEdit = true;
    },

    cDelete(offset) {
      this.$q
        .dialog({
          title: '<i class="fad fa-trash"></i>&nbsp;Confirm Delete',
          message: `Are you sure you want to delete this type?`,
          html: true,
          cancel: {
            noCaps: true,
            color: "grey-2",
            textColor: "black",
          },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          console.log("delete: ", offset);
          console.log("delete: ", this.entries[offset].uuid);
          API.post("/admin/contenttype/delete", {
            uuid: this.entries[offset].uuid,
            member: this.user,
          }).then((r) => {
            this.load();
          });
        });
    },

    load() {
      API.get("/admin/contenttypes").then((r) => {
        this.entries = [...r.data.data];
      });
    },

    save() {
      // add a tag in the tree
      const vm = this;
      console.log("save tag: ", this.current);
      API.post("/admin/contenttype/add", {
        entry: this.current,
        member: this.user,
      }).then((r) => {
        vm.showEdit = false;
        vm.load();
      });
    },

    getHeaders(tag, context) {
      return [
        {
          name: "X-Session",
          value: this.$store.getters["user/session"],
        },
      ];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~quasar-variables';

.drag-handle {
  float: left;
  // width: 50px;
  // height: 50px;
  cursor: grab;
  // background-color: $eigeel;
}
</style>
